import $ from 'jquery';
import flatpickr from "flatpickr";
import de from "flatpickr/dist/l10n/de.js";

// load CSRF cookie
$(document).ready(function() {
    // get CSRF cookie as JSON and save it as global variable

    let csrfCall = $.get("/api/ajax-getcsrf", function (data) {
        window.csrfTokenName = data.csrfTokenName;
        window.csrfTokenValue = data.csrfTokenValue;
        window.forms = data.forms;
    }, "json");

    $.when(csrfCall).then(function(data) {
        $(".component-formular form").each(function() {
            let formHandleHtml = $(this).parent().data('form');
            let $form = $(this);

            window.forms.forEach(function(windowData) {
                if(formHandleHtml == windowData.formHandle) {
                    $form.find('input[name="formHash"]').val(windowData.formHash); // + ' ajax added hash'
                }
            });
            $(this).find('input[name="CRAFT_CSRF_TOKEN"]').val(window.csrfTokenValue); // + ' ajax added csrf'
        })
    });
    
    //set noopener noreferrer attribute to external links
    $('a[href^="http"], a[href^="https"]').not('a[href*="' + location.hostname + '"]').attr({ rel: "noopener noreferrer" });

    // flickity sliders
    if ($('.homepage-slider.content-slider').length > 0) {
        let homepageSlider = new Flickity( '.homepage-slider.content-slider', { cellAlign: 'left', contain: true, fullscreen: true, wrapAround: true });
    }
    if ($('.teaser-group-inner').length > 0) {
        let teaserGroupSlider = new Flickity( '.teaser-group-inner', { cellAlign: 'left', contain: true, freeScroll: true, groupCells: 3, pageDots: false });
    }
    if ($('.slideshow').length > 0) {
        let slideshow = new Flickity( '.slideshow', { cellAlign: 'left', contain: true, fullscreen: true, pageDots: false, prevNextButtons: true, wrapAround: true });
    }
    if ($('.content-slider').length > 0) {
        let contentSlider = new Flickity( '.content-slider', { cellAlign: 'left', contain: true, fullscreen: true, pageDots: false, prevNextButtons: false });
    }
    if ($('.content-slider-navigation').length > 0) {
        let contentSliderNavigation = new Flickity( '.content-slider-navigation', { cellAlign: 'left', contain: true, asNavFor: '.content-slider', pageDots: false, prevNextButtons: false });
    }

    let today = new Date();
    flatpickr(".time-picker", {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true,
        defaultDate: today
    });

    flatpickr(".date-picker", {
        dateFormat: "d.m.Y",
        defaultDate: today,
        firstDayOfWeek: 0,
        locale: "de"
    });

    // flatpickr(".date-picker-range", {
    //     mode: "range",
    //     minDate: "today",
    //     dateFormat: "d.m.Y",
    //     firstDayOfWeek: 0,
    //     locale: "de",
    //     onChange: function(e) {
    //         let startDate = new Date(e[0]).toLocaleDateString('de-CH');
    //         let endDate = new Date(e[1]).toLocaleDateString('de-CH');
    //
    //         let eventFilters = [];
    //         $(".filter-checkbox input:checked").each(function(){
    //             eventFilters.push($(this).val());
    //         });
    //
    //         const filterCategory = document.querySelector('section.events').dataset.filter ?? 'events';
    //
    //         console.log(document.querySelector('section.events').dataset.filter);
    //
    //         $.ajax({
    //             type: "GET",
    //             url: "/api/ajax-" + filterCategory + "?filtercategories=" + eventFilters + "&startDate=" + startDate + "&endDate=" + endDate + "&cache=" + Math.floor(Date.now() / 1000),
    //             success: function(res) {
    //                 if( res.length > 0 ) {
    //                     $(".event-results").empty().html(res);
    //                 }
    //             },
    //             error: function(err) {
    //                 console.log("error:" + err.responseText);
    //             }
    //         });
    //     }
    // });

    // $(".clear-datefilter").click(function(){
    //
    //     const filterCategory = document.querySelector('section.events').dataset.filter ?? 'events';
    //
    //     $.ajax({
    //         type: "GET",
    //         url: "/api/ajax-" + filterCategory,
    //         success: function(res) {
    //             if( res.length > 0 ) {
    //                 $(".event-results").empty().html(res);
    //             }
    //         },
    //         error: function(err) { console.log("error:" + err.responseText) }
    //     });
    //     flatpickr('.date-picker-range', '.date-picker').clear();
    // });

    let eventFilters = [];
    $(".events .filter-checkbox input").change(function(e) {
        let cat = $(this).val();
        flatpickr('.date-picker-range', '.date-picker').clear();

        if (eventFilters.indexOf(cat) === -1) {
            eventFilters.push(cat);
        } else {
            eventFilters = eventFilters.filter(b => b !== cat);
        }

        const filterCategory = document.querySelector('section.events').dataset.filter ?? 'events';

        $.ajax({
            type: "GET",
            url: "/api/ajax-" +filterCategory+ "?filtercategories=" + eventFilters + "&cache=" + Math.floor(Date.now() / 1000),
            success: function(res) {
                if( res.length > 0 ) {
                    $(".event-results").empty().html(res);
                }
            },
            error: function(err) { console.log("error:" + err.responseText) }
        });
    });

    let roomFilters = [];
    $(".rooms .filter-checkbox input").change(function(e) {
        let cat = $(this).val();

        if (roomFilters.indexOf(cat) === -1) {
            roomFilters.push(cat);
        } else {
            roomFilters = roomFilters.filter(b => b !== cat);
        }

        $.ajax({
            type: "GET",
            url: "/api/ajax-rooms" + "?filtercategories=" + roomFilters + "&cache=" + Math.floor(Date.now() / 1000),
            success: function(res) {
                if( res.length > 0 ) {
                    $(".room-results").empty().html(res);
                }
            },
            error: function(err) { console.log("error:" + err.responseText) }
        });
    });

    // Google Maps
    if ($('#googleMaps').length > 0) {
        function initMap() {
            const hdw = { lat: 47.52438586297549, lng: 7.689301655471449 };
            const mapOptions = {
                zoom: 17,
                center: hdw,
                draggable: false,
                streetViewControl: false,
                gestureHandling: "none",
                zoomControl: false,
                mapTypeControl: false,
                fullscreenControl: false,
                styles: [{"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"color":"#444444"}]},{"featureType":"landscape","elementType":"all","stylers":[{"color":"#f2f2f2"}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"all","stylers":[{"saturation":-100},{"lightness":45}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"road.arterial","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#dde6e8"},{"visibility":"on"}]}]
            }
            const mapElement = document.getElementById("googleMaps");
            const map = new google.maps.Map(mapElement, mapOptions);
            const contentString = '<div class="pb-4 p-2 pr-4 map-fontscale text-white"><strong>Haus der Wirtschaft</strong><br>Hardstrasse 1, 4133 Pratteln<br><a class="text-white d-inline-block mt-2" href="tel:+41619276464">Tel: 061 927 64 64</a><br><a class="text-white mt-2 d-inline-block" href="mailto:info@hdw.ch">info@hdw.ch</a></div>';
            const infowindow = new google.maps.InfoWindow({
                content: contentString,
            });
            const icon = {
                url: "/images/icons/map-marker.svg",
                anchor: new google.maps.Point(25,50),
                scaledSize: new google.maps.Size(50,50),
            }
            const marker = new google.maps.Marker({
                position: hdw,
                map: map,
                icon: icon,
            });

            marker.addListener("click", () => {
                infowindow.open(map, marker);
            });
        }
        initMap();
    }
});
