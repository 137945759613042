// Importing scripts
import * as bootstrap from 'bootstrap';
import $ from "jquery";

// Custom Scripts
import './js/cookie-consent';
import './js/scripts';
//import './bootstrap/bootstrap.js';

// Importing styles
import './scss/app.scss';

// Accept HMR
if (module && module.hot) {
    module.hot.accept();
}

window.bootstrap = bootstrap;
window.jQuery = $;
window.$ = $;